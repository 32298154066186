<script>
import BaseListing from '../../../share/ListingWithEditMode'

export default {
  mixins: [
    BaseListing
  ],
  data () {
    return {
      service: this.$route.meta.acl.service,
      headerTitle: { title: 'Ustawienia online', description: 'Lista' },
      editModeUrlName: '',
      gridDataSource: null,
      gridName: 'grid_calendar_business_hours',
      editEventName: '',
      gridColumns: [
        { id: 0, data: 'employee', title: 'Pracownik' },
        { id: 1, data: 'startAt', title: 'Od' },
        { id: 2, data: 'endAt', title: 'Do' },
        { id: 4, data: 'priority', title: 'Priorytet' },
        {
          id: 5,
          data: 'cyclicity',
          title: 'Cykliczność',
          render (data) {
            let length = ['Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So', 'Nd']
            let cyclicity = data.toString(2).split('').reverse().map(e => e === '1')
            return length.filter((element, index) => {
              return cyclicity.hasOwnProperty(index) && cyclicity[index] && length[index]
            })
          },
          searchable: false,
          orderable: false
        }
      ]
    }
  },
  created () {
    this.getInitEditModeUrlName()
    this.getInitEditEventName()
    this.getInitGridDataSource()
  },
  methods: {
    getInitEditModeUrlName () {
      this.editModeUrlName = `${this.service}_calendar_businessHours_edit`
    },
    getInitEditEventName () {
      this.editEventName = `${this.service}:calendar:businessHoursEdit`
    },
    getInitGridDataSource () {
      this.gridDataSource = { service: this.service, url: `/business-hours` }
    }
  }
}
</script>
